import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '328px',
    maxHeight:'415px',
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

export default function FreeFinish({showModal, setShowModal, FreeDoubt, codeSnippet}) {
    const handleClose = () => setShowModal(false); 

  function BuyNow() {
      let func = new Function(codeSnippet);
      func();
  }
    
    return (
        <div className='free-doubt-main'>
            <Modal
                open={showModal}
            >
                <Box sx={style}>
                    <img src='/free-finish.png' />
                    <div className='free-heading'>
                        Your trial has ended !
                    </div>

                    <div className='free-text1'>{`You have exceed your free limit of ${FreeDoubt} doubts`}</div>
                    <div className='free-text2'> Still have doubts ?</div>
                    <div className='free-text2'>Purchase this feature to continue using</div>

                    <div className='free-btn-container'>
                        <Button variant="contained" onClick={BuyNow}>Buy Now</Button>
                        <Button variant="text" sx={{ mt: 1 }} onClick={handleClose}>Go Back</Button>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}
