import React, { useState ,useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/home";
// import Downtime from "./components/downtime";

function App() {
  // const[isLLMDown, setIsLLMDown]=useState(false);

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(`${process.env.REACT_APP_URL1}/downtime`);
    //     const data = await response.json();
    //     console.log(data);
    //     console.log(process.env.REACT_APP_API_URL)
    //     setIsLLMDown(data.isLLMDown);
    //   } catch (error) {
    //     console.error("Error fetching data from API", error);
    //     setIsLLMDown(false);
    //   }
    // };
  
    // fetchData();s
  }, []);
  

  return (
    <>
      <Router>
        <Routes>
       
        <Route path="/" element={ <Home />} />
        
        </Routes>
      </Router>
    </>
  );
}

export default App;
